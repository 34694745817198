// Home.tsx
import React from 'react';
import Head from '../Components/Header/Head';
import Exclusive from '../Components/List/Exclusive';
import News from '../Components/List/News';
import Phones from '../Components/List/Phones';
import PhonesNGadget from '../Components/List/PhonesNGadgets';
import NewsNFeature from '../Components/List/NewsNFeature';
import Apps from '../Components/List/Apps';
import OSNReviews from '../Components/List/OSNReviews';
import Footer from '../Components/Footer/Footer';
import UserNav from '../Components/Nav/UserNav';
import TopStoriesNExclusiveGrid from '../Components/List/TopStoriesNExclusiveGrid';

const Home: React.FC = () => {
  return (
    <div>
     <Head/>
      <UserNav/>
      <TopStoriesNExclusiveGrid/>
      <Exclusive/>
      <NewsNFeature/>
      <PhonesNGadget/>
      <Apps/>
      <OSNReviews/>
    <Footer/>
    </div>
  );
};

export default Home;
