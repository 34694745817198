import React from 'react';
import { Box, Image, Link, Text, Flex, Heading, Button, useBreakpointValue } from '@chakra-ui/react';

// Sample JSON Data
const topStoriesData = [
  {
    "id": 1,
    "image": "https://www.androidauthority.com/wp-content/uploads/2024/12/Apple-TV-Plus-See-For-Yourself-X-Image.jpg",
    "title": "Apple TV Plus is letting you stream for free this coming weekend, January 4 and 5 (Updated)",
    "author": "Matt Horne",
    "category": "News",
    "link": "https://www.androidauthority.com/apple-tv-plus-see-for-yourself-3511647/",
    "date": "January 4" // Example date
  },
  {
    "id": 2,
    "image": "https://www.androidauthority.com/wp-content/uploads/2024/12/S24-Ultra-multi-touch-scaled.jpg",
    "title": "After 16 years, Android still doesn’t make the most of its touchscreen",
    "author": "Zac Kew-Denniss",
    "category": "Features",
    "link": "https://www.androidauthority.com/android-embrace-touchscreen-multi-touch-3509346/",
    "date": "January 3" // Example date
  },

  {
    "id": 3,
    "image": "https://www.androidauthority.com/wp-content/uploads/2024/12/S24-Ultra-multi-touch-scaled.jpg",
    "title": "After 16 years, Android still doesn’t make the most of its touchscreen",
    "author": "Zac Kew-Denniss",
    "category": "Features",
    "link": "https://www.androidauthority.com/android-embrace-touchscreen-multi-touch-3509346/",
    "date": "January 3" // Example date
  },

  {
    "id": 4,
    "image": "https://www.androidauthority.com/wp-content/uploads/2024/12/S24-Ultra-multi-touch-scaled.jpg",
    "title": "After 16 years, Android still doesn’t make the most of its touchscreen",
    "author": "Zac Kew-Denniss",
    "category": "Features",
    "link": "https://www.androidauthority.com/android-embrace-touchscreen-multi-touch-3509346/",
    "date": "January 3" // Example date
  },

  {
    "id": 5,
    "image": "https://www.androidauthority.com/wp-content/uploads/2024/12/S24-Ultra-multi-touch-scaled.jpg",
    "title": "After 16 years, Android still doesn’t make the most of its touchscreen",
    "author": "Zac Kew-Denniss",
    "category": "Features",
    "link": "https://www.androidauthority.com/android-embrace-touchscreen-multi-touch-3509346/",
    "date": "January 3" // Example date
  },

  {
    "id": 6,
    "image": "https://www.androidauthority.com/wp-content/uploads/2024/12/S24-Ultra-multi-touch-scaled.jpg",
    "title": "After 16 years, Android still doesn’t make the most of its touchscreen",
    "author": "Zac Kew-Denniss",
    "category": "Features",
    "link": "https://www.androidauthority.com/android-embrace-touchscreen-multi-touch-3509346/",
    "date": "January 3" // Example date
  },
  {
    "id": 7,
    "image": "https://www.androidauthority.com/wp-content/uploads/2024/12/S24-Ultra-multi-touch-scaled.jpg",
    "title": "After 16 years, Android still doesn’t make the most of its touchscreen",
    "author": "Zac Kew-Denniss",
    "category": "Features",
    "link": "https://www.androidauthority.com/android-embrace-touchscreen-multi-touch-3509346/",
    "date": "January 3" // Example date
  },

  // Add other stories here
];

// Function to generate a random color
const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const TopStories = () => {
  const breakpoint = useBreakpointValue({ base: 'mobile', md: 'tablet', lg: 'pc' });

  const hoverColor = '#00d49f'; // Color to use on hover

  return (
    <Box p={4}>
      <Heading mb={2} fontSize="lg" textAlign="start">Top Stories</Heading>
      <Box display="flex" flexDirection="column" alignItems="stretch" gap={2}>
        {topStoriesData.map((story) => (
          <Link
            key={story.id}
            href={story.link}
            isExternal
            display="flex"
            flexDirection="row"
            alignItems="stretch"
            borderRadius="md"
            overflow="hidden"
            boxShadow="sm"
            _hover={{ boxShadow: 'md', color: hoverColor }}
            width="100%"
            position="relative"
            css={{
              '&:after': {
                content: '""',
                position: 'absolute',
                right: 0,  // Move the color bar to the right side
                top: 0,
                bottom: 0,
                width: '5px', // Thickness of the color bar
                backgroundColor: getRandomColor(), // Random color for the right bar
              }
            }}
          >
            <Flex direction="row" align="center" width="100%" position="relative">
              <Box position="relative" flexShrink={0} mr={4}>
                <Image
                  boxSize="100px"
                  objectFit="cover"
                  src={story.image}
                  alt={story.title}
                  borderRadius="lg" // Rounded corners for the thumbnail
                />
                {/* Date over thumbnail */}
                <Box
                  position="absolute"
                  top="65px"
                  left="10px"
                  backgroundColor="rgba(49, 250, 109, 0.7)" // Light red background
                  color="white"
                  padding="5px"
                  borderRadius="md"
                  fontSize="sm"
                  fontWeight="bold"
                >
                  {story.date}
                </Box>
              </Box>
              <Box p={2} flex="1">
                <Text
                  fontSize={breakpoint === 'mobile' ? 'sm' : 'md'}
                  fontWeight="bold"
                  mb={1}
                  _hover={{ color: hoverColor }}
                >
                  {story.title}
                </Text>
                <Text fontSize={breakpoint === 'mobile' ? 'xs' : 'sm'} mb={1}>
                  {story.author}
                </Text>
                <Text fontSize={breakpoint === 'mobile' ? 'xs' : 'sm'} color="gray.500">
                  {story.category}
                </Text>
              </Box>
            </Flex>
          </Link>
        ))}
      </Box>
      {/* Right-aligned Button */}
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button
        size="sm"
          colorScheme="teal"
          variant="solid"
          bg={hoverColor}
          color="black"
          _hover={{ bg: hoverColor, color: 'white' }}
        >
          Load More
        </Button>
      </Box>
    </Box>
  );
};

export default TopStories;
