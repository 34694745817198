import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  SimpleGrid,
  useColorModeValue,
  Spinner,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { motion } from "framer-motion";
import { useMenuItems } from "./menuItems";

const MotionChevronDownIcon = motion(ChevronDownIcon);
const MotionChevronUpIcon = motion(ChevronUpIcon);

const Menu: React.FC = () => {
  const [activeCategory, setActiveCategory] = useState<string | null>(null);
  const menuItems = useMenuItems();
  const [isLoading, setIsLoading] = useState(true);

  // Ensure loading state updates correctly
  useEffect(() => {
    if (menuItems.length > 0) {
      setIsLoading(false);
    }
  }, [menuItems]);

  const handleCategoryToggle = (categoryName: string, link: string, hasSubItems: boolean) => {
    if (hasSubItems) {
      setActiveCategory((prevCategory) =>
        prevCategory === categoryName ? null : categoryName
      );
    } else {
      // Redirect to the link if no sub-items exist
      window.location.href = link;
    }
  };

  const textColorDefault = useColorModeValue("gray.700", "white");
  const menuHoverColor = useColorModeValue("black", "#00d49f");
  const submenuLinkColor = "white";
  const submenuLinkHoverColor = "#00d49f";
  const arrowColor = useColorModeValue("#00d49f", "#00d49f");

  if (isLoading) {
    return (
      <HStack spacing={4} align="center">
        <Spinner size="lg" color={menuHoverColor} />
      </HStack>
    );
  }

  if (menuItems.length === 0) {
    return (
      <HStack spacing={4} align="center">
        <Box color="gray.500">No categories available</Box>
      </HStack>
    );
  }

  return (
    <HStack spacing={4} align="flex-start">
      {menuItems.map((category) => (
        <Popover
          key={category.id}
          trigger="hover"
          placement="bottom-start"
          isOpen={activeCategory === category.name}
          onClose={() => setActiveCategory(null)}
        >
          <PopoverTrigger>
            <Button
              variant="ghost"
              _hover={{ color: menuHoverColor, bg: "transparent" }}
              px={4}
              fontWeight="medium"
              rightIcon={
                activeCategory === category.name ? (
                  <MotionChevronUpIcon
                    initial={{ rotate: 0 }}
                    animate={{ rotate: 180 }}
                    transition={{ duration: 0.3 }}
                    color={menuHoverColor}
                  />
                ) : (
                  <MotionChevronDownIcon
                    initial={{ rotate: 0 }}
                    animate={{ rotate: -180 }}
                    transition={{ duration: 0.3 }}
                    color={menuHoverColor}
                  />
                )
              }
              color={textColorDefault}
              onClick={() => handleCategoryToggle(category.name, category.link, category.subItems.length > 0)}
            >
              {category.name}
            </Button>
          </PopoverTrigger>
          <PopoverContent
            width="800px"
            p={4}
            bg="rgba(0, 0, 0, 0.6)"
            backdropFilter="blur(10px)"
            boxShadow="lg"
            borderRadius="md"
            borderWidth="0"
          >
            <PopoverArrow bg={arrowColor} borderColor={arrowColor} />
            <PopoverBody p={0}>
              {category.subItems.length > 0 ? (
                <SimpleGrid columns={3} spacing={3}>
                  {category.subItems.map((item) => (
                    <Box
                      key={item.name}
                      as="a"
                      href={item.link}
                      p={2}
                      borderRadius="md"
                      _hover={{ color: submenuLinkHoverColor, textDecoration: "none" }}
                      display="block"
                      fontWeight="medium"
                      color={submenuLinkColor}
                    >
                      {item.name}
                    </Box>
                  ))}
                </SimpleGrid>
              ) : (
                <Box p={2} color="gray.300" fontSize="sm">
                  No subcategories available
                </Box>
              )}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      ))}
    </HStack>
  );
};

export default Menu;
