// App.tsx
import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UserNav from './Components/Nav/UserNav';
import Home from './Pages/Home';
import Signup from './Pages/Users/Signup';
import Login from './Pages/Users/Login';
import Dashboard from './Pages/Users/Dashboard';

const App: React.FC = () => {
  return (
    <ChakraProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signup" element={<Signup />} /> 
          <Route path="/login" element={<Login />} /> 
          <Route path="/dashboard" element={<Dashboard />} /> 

        </Routes>
      </Router>
    </ChakraProvider>
  );
};

export default App;
