import React from 'react';
import { Box, Grid, Image, Text, Heading, Link } from '@chakra-ui/react';

// Android Authority Data
const articles = [
  {
    id: 1,
    imageUrl: "https://www.androidauthority.com/wp-content/uploads/2024/05/Google-Pixel-9-Pro-angled-front-view-gray.jpg",
    title: "Exclusive: Google Pixel 9 series set for sweeping camera improvements",
    description: "Google is making some major and controversial changes to the cameras in the Pixel 9 series.",
    author: "Kamila Wojciechowska",
    href: "https://www.androidauthority.com/google-pixel-9-series-camera-improvements-2024/"
  },
  {
    id: 2,
    imageUrl: "https://www.androidauthority.com/wp-content/uploads/2024/07/google-home-homescreen-widget-favorites-scaled.jpg",
    title: "I’ve already ditched the new Google Home widget and watch tile",
    description: "",
    author: "Rita El Khoury",
    href: "https://www.androidauthority.com/google-home-widget-watch-tile-2024/"
  },
  {
    id: 3,
    imageUrl: "https://www.androidauthority.com/wp-content/uploads/2024/07/goes-hard.jpg",
    title: "Giant Pixel 9 leak gives us our first real-world look at the Fold",
    description: "",
    author: "Kamila Wojciechowska",
    href: "https://www.androidauthority.com/pixel-9-fold-leak-2024/"
  },
  {
    id: 4,
    imageUrl: "https://www.androidauthority.com/wp-content/uploads/2024/07/OnePlus-Watch-2R-Classic-Watch-face-scaled.jpg",
    title: "The OnePlus Watch 2R just made Wear OS with fantastic battery life more accessible",
    description: "",
    author: "Kaitlyn Cimino",
    href: "https://www.androidauthority.com/oneplus-watch-2r-wear-os-battery-life-2024/"
  },
  {
    id: 5,
    imageUrl: "https://www.androidauthority.com/wp-content/uploads/2024/06/Amazon-Prime-Day-2024-graphic-e1719294616746.jpg",
    title: "Prime Day deals are live: Here are the best we’ve found so far",
    description: "",
    author: "Matt Horne",
    href: "https://www.androidauthority.com/prime-day-2024-best-deals/"
  },
  {
    id: 6,
    imageUrl: "https://www.androidauthority.com/wp-content/uploads/2024/06/Amazon-Prime-Day-2024-graphic-e1719294616746.jpg",
    title: "Prime Day deals are live: Here are the best we’ve found so far",
    description: "",
    author: "Matt Horne",
    href: "https://www.androidauthority.com/prime-day-2024-best-deals/"
  }
];

const Gallery = () => {
  return (
    <Box p={4}>
      <Heading mb={2} fontSize="lg" textAlign="start">Gallery</Heading>
      <Grid
        templateColumns="1fr" // First row 1 item
        templateRows="repeat(3, auto)"
        gap={4}
      >
        {/* 1st Row - 1 item (400x200) */}
        <Link href={articles[0].href} isExternal>
          <Box key={articles[0].id} w="100%" h="230px" bg="gray.200" borderRadius="md" overflow="hidden" position="relative">
            <Image
              src={articles[0].imageUrl}
              alt={articles[0].title}
              objectFit="cover"
              w="100%"
              h="100%"
              _hover={{
                transform: "scale(1.1)", // Zoom in on hover
                transition: "transform 0.3s ease-in-out",
              }}
            />
            <Text 
              position="absolute" 
              bottom="10px" 
              left="10px" 
              color="white" 
              fontWeight="bold" 
              fontSize="lg" 
              textShadow="2px 2px 4px rgba(0, 0, 0, 0.6)"
              _hover={{ color: "#00d49f" }}
            >
              {articles[0].title}
            </Text>
          </Box>
        </Link>

        {/* 2nd Row - 2 items (300x300) */}
        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
          {articles.slice(1, 3).map(article => (
            <Link key={article.id} href={article.href} isExternal>
              <Box w="100%" h="300px" bg="gray.200" borderRadius="md" overflow="hidden" position="relative">
                <Image
                  src={article.imageUrl}
                  alt={article.title}
                  objectFit="cover"
                  w="100%"
                  h="100%"
                  _hover={{
                    transform: "scale(1.1)", // Zoom in on hover
                    transition: "transform 0.3s ease-in-out",
                  }}
                />
                <Text 
                  position="absolute" 
                  bottom="10px" 
                  left="10px" 
                  color="white" 
                  fontWeight="bold" 
                  fontSize="lg" 
                  textShadow="2px 2px 4px rgba(0, 0, 0, 0.6)"
                  _hover={{ color: "#00d49f" }}
                >
                  {article.title}
                </Text>
              </Box>
            </Link>
          ))}
        </Grid>

        {/* 3rd Row - 3 items (200x200) */}
        <Grid templateColumns="repeat(3, 1fr)" gap={4}>
          {articles.slice(3).map(article => (
            <Link key={article.id} href={article.href} isExternal>
              <Box w="100%" h="200px" bg="gray.200" borderRadius="md" overflow="hidden" position="relative">
                <Image
                  src={article.imageUrl}
                  alt={article.title}
                  objectFit="cover"
                  w="100%"
                  h="100%"
                  _hover={{
                    transform: "scale(1.1)", // Zoom in on hover
                    transition: "transform 0.3s ease-in-out",
                  }}
                />
                <Text 
                  position="absolute" 
                  bottom="10px" 
                  left="10px" 
                  color="white" 
                  fontWeight="bold" 
                  fontSize="lg" 
                  textShadow="2px 2px 4px rgba(0, 0, 0, 0.6)"
                  _hover={{ color: "#00d49f" }}
                >
                  {article.title}
                </Text>
              </Box>
            </Link>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Gallery;
