import { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Box, Heading, Image, Text, Link, Spinner } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import PostCreateBox from '../../Components/Status/PostCreateBox';
import MemberPosts from '../../Components/Status/MemberPosts';

const Dashboard = () => {
  const [userData, setUserData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No token found.');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.post(
          'http://127.0.0.1/wordpress/wp-json/buddypress/v1/members/me',
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setUserData(response.data);
        setLoading(false);
      } catch (err: any) {
        setError('Failed to fetch user data.');
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('authToken'); // Remove token from localStorage
    navigate('/login'); // Redirect to login page
  };

  if (loading) {
    return <Spinner size="xl" />;
  }

  if (error) {
    return <Text>{error}</Text>;
  }

  return (
    <Box p={5}>
      <Heading mb={4}>Dashboard</Heading>
      {userData && (
        <Box>
          <Heading size="lg">Welcome, {userData.name}</Heading>
          <Image
            src={userData.avatar_urls.full}
            alt="User Avatar"
            boxSize="150px"
            borderRadius="full"
            mb={4}
          />
          <Text fontSize="xl">User Login: {userData.user_login}</Text>
          <Text fontSize="xl">
            Member Link: <Link href={userData.link} isExternal>{userData.link}</Link>
          </Text>
          <Text fontSize="xl">Friendship Status: {userData.friendship_status ? 'Friends' : 'Not Friends'}</Text>
          <Text fontSize="xl">Mention Name: {userData.mention_name}</Text>
          <Text fontSize="xl">
            Avatar Thumbnail: <Image src={userData.avatar_urls.thumb} alt="Thumbnail Avatar" boxSize="50px" />
          </Text>

          <Box mt={6}>
            {/* Pass userData values to PostCreateBox component */}
            <PostCreateBox
              userId={userData.id}
              userName={userData.name}
              avatarUrl={userData.avatar_urls.full}
            />
            <MemberPosts/>
            <Button colorScheme="teal" onClick={handleLogout}>
              Logout
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Dashboard;
