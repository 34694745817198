import React, { useState, useEffect } from 'react';
import { Box, VStack, Text, HStack, Avatar, Spinner, Button, List, ListItem } from '@chakra-ui/react';
import axios from 'axios';

// Define types for Post data
interface Post {
  id: number;
  title: string;
  content: {
    rendered: string;
  };
  user_avatar: {
    full: string;
  };
  favorited: boolean;
  user_id: number;
  like_count: number;
  likes: { user_id: number; user_avatar: string; user_name: string }[];
}

const MemberPosts: React.FC = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [userId, setUserId] = useState<number>(5); // Example user ID, update accordingly

  // Function to fetch posts
  const fetchPosts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `http://127.0.0.1/wordpress/wp-json/buddypress/v1/activity`,
        {
          params: {
            per_page: 20,
            page: 1,
          },
        }
      );
      setPosts(response.data);
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false);
    }
  };

  // Like an activity
  const likeActivity = async (postId: number) => {
    try {
      await axios.post(
        `http://127.0.0.1/wordpress/wp-json/buddypress/v1/activity/${postId}/likes`,
        {
          user_id: userId,
        }
      );
      
      // Update the state for the liked post
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === postId
            ? { ...post, favorited: true, like_count: post.like_count + 1 }
            : post
        )
      );
    } catch (error) {
      console.error('Error liking post:', error);
    }
  };

  // Unlike an activity
  const unlikeActivity = async (postId: number) => {
    try {
      await axios.delete(
        `http://127.0.0.1/wordpress/wp-json/buddypress/v1/activity/${postId}/likes`,
        {
          data: {
            user_id: userId,
          },
        }
      );

      // Update the state for the unliked post
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === postId
            ? { ...post, favorited: false, like_count: post.like_count - 1 }
            : post
        )
      );
    } catch (error) {
      console.error('Error unliking post:', error);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <Box p={5}>
      <VStack spacing={6}>
        {loading && <Spinner size="xl" />}
        {posts.length > 0 ? (
          posts.map((post) => (
            <Box key={post.id} bg="white" p={4} borderRadius="md" shadow="md" width="full">
              <HStack spacing={4}>
                <Avatar size="md" src={post.user_avatar.full || '/default-avatar.png'} />
                <VStack align="start" spacing={2}>
                  <Text fontSize="lg" fontWeight="bold">
                    <span dangerouslySetInnerHTML={{ __html: post.title }} />
                  </Text>
                  <Text>
                    <span dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
                  </Text>
                  <HStack spacing={4}>
                    {post.favorited ? (
                      <Button colorScheme="red" onClick={() => unlikeActivity(post.id)}>
                        Unlike
                      </Button>
                    ) : (
                      <Button colorScheme="teal" onClick={() => likeActivity(post.id)}>
                        Like
                      </Button>
                    )}
                    <Text>{post.like_count} Likes</Text>
                  </HStack>

                  {/* Show users who liked the post */}
                  <Box>
                    <Text fontWeight="bold">Users who liked this post:</Text>
                    <List spacing={2}>
                      {(post.likes || []).map((like) => (
                        <ListItem key={like.user_id}>
                          <HStack spacing={3}>
                            <Avatar size="sm" src={like.user_avatar} />
                            <Text>{like.user_name}</Text>
                          </HStack>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </VStack>
              </HStack>
            </Box>
          ))
        ) : (
          <Text>No posts available</Text>
        )}
      </VStack>
    </Box>
  );
};

export default MemberPosts;
