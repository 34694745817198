import { useState } from 'react';
import axios from 'axios';
import { 
  Box, 
  Button, 
  FormControl, 
  FormLabel, 
  Input, 
  FormErrorMessage, 
  useToast, 
  Stack, 
  Heading 
} from '@chakra-ui/react';

const Signup = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const toast = useToast();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const userData = {
      username,
      email,
      password,
    };

    try {
      const response = await axios.post(
        'http://127.0.0.1/wordpress/wp-json/custom/v1/signup',
        userData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setSuccessMessage('Signup successful!');
        setUsername('');
        setEmail('');
        setPassword('');
        toast({
          title: 'Success',
          description: 'Your account has been created.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (err: any) {
      setError('Signup failed. Please try again.');
      toast({
        title: 'Error',
        description: 'There was an issue with the signup process.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box maxW="md" mx="auto" p={4}>
      <Heading as="h2" size="xl" mb={6} textAlign="center">
        Sign Up
      </Heading>
      
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
      {successMessage && <FormErrorMessage>{successMessage}</FormErrorMessage>}

      <form onSubmit={handleSubmit}>
        <Stack spacing={4}>
          <FormControl isRequired>
            <FormLabel htmlFor="username">Username</FormLabel>
            <Input
              id="username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter your username"
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel htmlFor="password">Password</FormLabel>
            <Input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
            />
          </FormControl>

          <Button 
            type="submit" 
            colorScheme="blue" 
            size="lg"
          >
            Sign Up
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export default Signup;
