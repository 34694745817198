import React, { useState } from 'react';
import { Box, Button, Input, Textarea, Avatar, HStack, VStack, Text, useToast } from '@chakra-ui/react';
import axios from 'axios';

interface PostCreateBoxProps {
  userId: number;  // The ID of the user posting the status
  userName: string;  // User's full name
  avatarUrl: string;  // URL of the user's avatar
}

const PostCreateBox: React.FC<PostCreateBoxProps> = ({ userId, userName, avatarUrl }) => {
  const [content, setContent] = useState('');
  const [isPosting, setIsPosting] = useState(false);
  const toast = useToast();  // Initialize the toast hook

  // Function to handle post creation
  const handlePostSubmit = async () => {
    if (!content.trim()) return; // Prevent empty posts

    setIsPosting(true);
    
    const postData = {
      component: 'activity',
      type: 'activity_update',
      content: content,
      user_id: userId,
      primary_link: `http://127.0.0.1/wordpress/members/${userName}/`,
      avatar_urls: {
        full: avatarUrl,
        thumb: avatarUrl,
      },
      xprofile: {
        groups: [
          {
            name: 'Base',
            id: 1,
            fields: [
              {
                name: 'Name',
                id: 1,
                value: {
                  raw: userName,
                  unserialized: [userName],
                  rendered: `<p>${userName}</p>`,
                },
              },
            ],
          },
        ],
      },
      mention_name: userName,
      link: `http://127.0.0.1/wordpress/members/${userName}/`,
    };

    try {
      const response = await axios.post(
        'http://127.0.0.1/wordpress/wp-json/buddypress/v1/activity',
        postData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // Reset content and posting state after successful post
      setContent('');
      setIsPosting(false);

      // Display success toast
      toast({
        title: 'Post created successfully!',
        description: 'Your post has been shared.',
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      setIsPosting(false);
      console.error('Error creating post:', error);

      // Display error toast
      toast({
        title: 'Failed to create post',
        description: 'There was an error creating your post.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return (
    <Box bg="white" p={4} borderRadius="md" shadow="md" maxWidth="600px" mx="auto">
      <HStack spacing={4}>
        <Avatar size="md" src={avatarUrl} />
        <VStack align="start" spacing={2} width="full">
          <Text fontSize="lg" fontWeight="bold">{userName}</Text>
          <Textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder={`What's on your mind, ${userName}?`}
            size="md"
            minHeight="120px"
            resize="vertical"
            focusBorderColor="blue.500"
          />
          <HStack spacing={4} width="full">
            <Button
              colorScheme="blue"
              onClick={handlePostSubmit}
              isLoading={isPosting}
              loadingText="Posting"
              width="full"
            >
              Post
            </Button>
          </HStack>
        </VStack>
      </HStack>
    </Box>
  );
};

export default PostCreateBox;
