import { Box, Button, FormControl, FormLabel, Input, Stack, Heading, useToast } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Use useNavigate from react-router-dom

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const toast = useToast();
  const navigate = useNavigate(); // useNavigate hook for redirection

  // Check if the user is already logged in (i.e., if the token exists)
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      navigate('/dashboard'); // Redirect to dashboard if token exists
    }
  }, [navigate]);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();

    const loginData = {
      username: email,  // Use the email as the username
      password,
    };

    try {
      const response = await axios.post(
        'http://127.0.0.1/wordpress/wp-json/jwt-auth/v1/token', // Correct API endpoint
        loginData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.token) {
        toast({
          title: 'Login Successful',
          description: 'You have logged in successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        // Save token in local storage
        localStorage.setItem('authToken', response.data.token);

        // Redirect to the Dashboard
        navigate('/dashboard');
      }
    } catch (err: any) {
      toast({
        title: 'Login Failed',
        description: err.response?.data?.message || 'An error occurred.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box maxW="md" mx="auto" p={4}>
      <Heading as="h2" size="xl" mb={6} textAlign="center">
        Login
      </Heading>

      <form onSubmit={handleLogin}>
        <Stack spacing={4}>
          <FormControl isRequired>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel htmlFor="password">Password</FormLabel>
            <Input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
            />
          </FormControl>

          <Button
            type="submit"
            colorScheme="blue"
            size="lg"
            width="full"
          >
            Login
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export default Login;
