import { useEffect, useState } from "react";

interface SubItem {
  name: string;
  link: string;
}

interface MenuItem {
  id: number;
  name: string;
  link: string;
  subItems: SubItem[];
}

const transformCategories = (categories: any[]): MenuItem[] => {
  const categoryMap: Record<number, MenuItem> = {};
  const topLevelCategories: MenuItem[] = [];

  console.log("Processing categories:", categories);

  // Step 1: Initialize all categories in categoryMap
  categories.forEach((category) => {
    categoryMap[category.id] = {
      id: category.id,
      name: category.name,
      link: `/category/${category.id}`,
      subItems: [],
    };
  });

  // Step 2: Assign subcategories to their respective parents
  categories.forEach((category) => {
    if (category.parent !== 0 && categoryMap[category.parent]) {
      categoryMap[category.parent].subItems.push({
        name: category.name,
        link: `/category/${category.id}`,
      });
    }
  });

  // Step 3: Collect top-level categories (if parent === 0 OR if missing parent info)
  categories.forEach((category) => {
    if (category.parent === null || !categoryMap[category.parent]) {
      topLevelCategories.push(categoryMap[category.id]);
    }
  });

  console.log("Final transformed categories:", JSON.stringify(topLevelCategories, null, 2));

  return topLevelCategories;
};

export const useMenuItems = () => {
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          "https://bhbssc.com/androidhorizon/wp-json/wp/v2/categories/"
        );
        const data = await response.json();

        console.log("Fetched categories: ", JSON.stringify(data, null, 2));

        setMenuItems(transformCategories(data));
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  return menuItems;
};
