import React from 'react';
import { Box, Grid, useBreakpointValue } from '@chakra-ui/react';
import TopStories from './TopStories';
import Gallery from './ExclusiveGrid';

const TopStoriesNExclusiveGrid: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, md: false});

  return (
    <Box>
      <Grid
        templateColumns={isMobile ? '1fr' : '1fr 1fr'} // Single column on mobile, two columns on larger screens
        gap={4}
      >
        <Box>
          <Gallery /> {/* Render Phones component */}
        </Box>
        <Box>
          <TopStories /> {/* Render Gadgets component */}
        </Box>
      </Grid>
    </Box>
  );
};

export default TopStoriesNExclusiveGrid;
